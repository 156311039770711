.map-modal {
    > .segment {
        margin: 0 !important;
        padding: 0 !important;
    }
    width: 95vw !important;
    height: 90vh;
    &__content {
        display: flex;
    }
    &__filters {
        display: flex;
        flex-direction: column;
        width: 350px;
        height: 90vh;
        border-right: 1px solid #dfdfdf;
        .form {
            padding: 20px;
            > .field {
                position: relative;
                padding-bottom: 0;
                label {
                    padding-bottom: 5px;
                }
                .ui.radio.checkbox {
                    padding-left: 0;
                    padding-right: 25px;
                    padding-top: 5px;
                }
                .ui.checkbox:not(.radio) label {
                    font-weight: bold !important;
                    color: #4d4d4d;
                }
                .field-hint {
                    position: absolute;
                    left: 26px;
                    top: 17px;
                    font-size: 12px;
                    font-style: italic;
                }
            }
        }
    }
    &__map {
        width: calc(95vw - 350px);
        height: 90vh;
    }
    &__legend {
        margin-top: 20px;
        &-points {
            display: flex;
            flex-wrap: wrap;
            margin-top: 6px;
            .b-point {
                .circle-point {
                    margin: auto 6px auto 0;
                    width: 14px;
                    min-width: 14px;
                    height: 14px;
                }
                .car-point {
                    margin: auto 9px auto -3px;
                    width: 14px;
                    min-width: 14px;
                    height: 20px;
                }
                width: 50%;
                padding: 5px 5px 5px 0;
                &:nth-child(2n) {
                    padding: 5px 0 5px 5px;
                }

            }
        }

        .description {
            margin-top: auto !important;
            margin-bottom: auto !important;
        }
    }
    &__btn {
        width: 100%;
        margin-right: 0 !important;
    }
    &__line {
        height: 20px;
        background-color: #f6f7fa;
        border-top: 1px solid #dfdfdf;
        border-bottom: 1px solid #dfdfdf;
    }
    &__shipping-number {
        margin-top: auto;
        margin-bottom: auto;
        white-space: nowrap;
    }
    &__scroll {
        overflow: auto;
    }
    &__padding-20{
        padding: 20px;
    }
    &__first-points-list {
        // height: auto;
        overflow-y: auto;
        display: flex;
        // justify-content: space-between;
        margin-bottom: 10px;
        flex-direction: column;
    }
    &__first-points-list-item{
        cursor: pointer;
        padding: 10px;
        border: 1px solid #dfdfdf;
    }
    &__badge{
        margin-left: 10px;
        color: white;
        padding: 4px 8px;
        text-align: center;
        border-radius: 5px;
    }
    &__badge--green{
        background-color: rgb(1, 125, 67);
    }
    &__badge--purple{
        background-color: rgb(91, 32, 128);
    }
    &__badge--blue{
        background-color: rgb(0, 148, 185);
    }
}

.map {
    &__icon {
        cursor: pointer !important;
        width: 100px;
        height: 123px;
        background-color: #9a0000 !important;
    }
}

.b-point {
    cursor: pointer;
    display: flex;
    > div:last-child {
        margin: 2px;
    }
}

.circle-point {
    border: 3px solid white;
    outline: 2px solid black;
    background-color: black;
    border-radius: 50%;
    width: 8px;
    height: 8px;
    margin: 5px;
}

.map-modal__map {
    width: calc(95vw - 350px);
    height: 90vh;
    position: relative 
}

.leaflet-container {
    width: 100%;
    height: 100%; 
}

.leaflet-container {
    height: 100%;
    width: 100%;
}

.custom-div-icon {
    background: transparent;
    border: none;
}

.marker-cluster-small {
    background-color: rgba(66, 133, 244, 0.6);
}

.marker-cluster-small div {
    background-color: rgba(66, 133, 244, 0.8);
}

.marker-cluster-medium {
    background-color: rgba(66, 133, 244, 0.6);
}

.marker-cluster-medium div {
    background-color: rgba(66, 133, 244, 0.8);
}

.marker-cluster-large {
    background-color: rgba(66, 133, 244, 0.6);
}

.marker-cluster-large div {
    background-color: rgba(66, 133, 244, 0.8);
}